<template>
  <div>
    <v-row class="inlineDiv decodeAsName">
      <v-col class="inlineDiv">
        <associatedOption
          :fieldAttributes="{
            name: 'Decode as',
            associatedOption: decodeTypes,
          }"
          type="select"
          :fieldAttrInput="{}"
          field="decodeAs"
          v-model="DecodeAs"
          :templateContent="result"
        ></associatedOption>
      </v-col>
      <v-col class="httpBodyresultActionContainer inlineDiv">
        <errorActionFields
          v-if="DecodeAs != 'text'"
          :value="OnDecodeError"
          @input="updateBodyEvalError"
          :result="result"
        ></errorActionFields>
      </v-col>
    </v-row>
    <div class="httpBodyEvaluationContainer">
      <evalContentTable
        v-model="bodyEvalValue"
        operatorType="incNull"
        :forWhat="DecodeAs"
         :result="result"
        :isValid="isValid"
      ></evalContentTable>
    </div>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
import errorActionFields from "@/components/legacy/errorActionFields.vue";
import evalContentTable from "@/components/legacy/evalContentTable.vue";

export default {
  props: ["localStoredStep", "result","isValid"],
  data: function () {
    return {
      decodeTypes: [
        ["JSON", "JSON"],
        ["jwt", "JSON Web Token (JWT)"],
        ["text", "Text"],
        ["XML", "XML"],
      ],
    };
  },
  components: {
    associatedOption,

    errorActionFields,
    evalContentTable,
  },
  computed: {
    evalValue: {
      get() {
        let evalValue = {};
        if (
          typeof this.localStoredStep["res"] != "undefined" &&
          typeof this.localStoredStep["res"]["eval"] != "undefined"
        ) {
          evalValue = this.localStoredStep["res"]["eval"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("eval", newVal);
      },
    },

    DecodeAs: {
      get() {
        let DecodeAs = "JSON";

        if (typeof this.evalValue["DecodeAs"] != "undefined") {
          DecodeAs = this.evalValue["DecodeAs"];
        }

        return DecodeAs;
      },
      set(newVal) {
        this.$set(this.evalValue, "DecodeAs", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    bodyEvalValue: {
      get() {
        let bodyEvalValue = {};

        if (typeof this.evalValue["Body"] != "undefined") {
          bodyEvalValue = this.evalValue["Body"];
        }

        return bodyEvalValue;
      },
      set(newVal) {
        console.log("new eval body set");
        this.$set(this.evalValue, "Body", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    OnWrongStatusType() {
      let OnWrongStatusType = {};

      if (typeof this.evalValue["OnWrongStatusType"] != "undefined") {
        OnWrongStatusType = this.evalValue["OnWrongStatusType"];
      }

      return OnWrongStatusType;
    },

    OnDecodeError() {
      let OnDecodeError = {};

      if (typeof this.evalValue["OnDecodeError"] != "undefined") {
        OnDecodeError = this.evalValue["OnDecodeError"];
      }

      return OnDecodeError;
    },
  },
  methods: {
    updateBodyEvalError(value) {
      this.$set(this.evalValue, "OnDecodeError", value);
      this.updateResponseKey("eval", this.evalValue);
    },

    updateResponseKey(index, newVal) {
      // console.log("update parent");
      // let localStoredStep = { ...this.localStoredStep };

      this.$set(this.localStoredStep["res"], index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
    checkOnWrongStatusType(field, value) {
      // console.log("checkOnWrongStatusType");
      this.$set(this.evalValue.OnWrongStatusType, field, value);
      this.updateResponseKey("eval", this.evalValue);
    },
  },
};
</script>
<template>
  <configContainer :title="languagePack('testJSConfig', 'responsePacket')">
    <template v-slot:content>
      <v-row>
        <v-col class="inlineDiv HTTPCodesContainer">
          <httpStatusCode
            v-model="ExpectedStatusType"
            :HTTPCodes="HTTPCodes"
            :result="result"
          ></httpStatusCode>
        </v-col>
        <v-col>
          <errorActionFields
            :value="OnWrongStatusType"
            @input="checkOnWrongStatusType(...arguments)"
            :result="result"
          ></errorActionFields>
        </v-col>
      </v-row>

      <tabsStructure
        :tabNames="tabNames"
        :isValid="isValid"
        :tabsPrefix="idPrefix + '_tabs'"
        v-on="$listeners"
      >
        <template v-slot:tabsContent="data">
          <div v-if="data.item.index == 'Header'">
            <evalContentTable
              :titleArray="titleArray"
              v-model="headerEvalValue"
              operatorType="incNull"
              :correctOperators="true"
              :result="result"
              :isValid="isValid"
              :linePrefix="idPrefix + '_header'"
              :hideDetails="true"
            ></evalContentTable>
          </div>

          <div v-if="data.item.index == 'Body'">
            <httpResponseBody
              :localStoredStep="localStoredStep"
              v-on="$listeners"
              :result="result"
              :isValid="isValid"
              :hideDetails="false"
            ></httpResponseBody>
          </div>
        </template>
      </tabsStructure>
      <onTimeOutNoResult
        onEvent="On Timeout"
        v-model="onTimeout"
        :result="result"
      ></onTimeOutNoResult>
    </template>
  </configContainer>
</template>
<script>
import httpStatusCode from "@/components/legacy/httpStatusCode.vue";
import httpResponseBody from "@/components/legacy/httpResponseBody.vue";

import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";
import tabsStructure from "@/components/legacy/tabsStructure.vue";
import errorActionFields from "@/components/legacy/errorActionFields.vue";
import evalContentTable from "@/components/legacy/evalContentTable.vue";
import configContainer from "@/commonComponents/configContainer.vue";
export default {
  props: ["result", "localStoredStep", "isValid", "idPrefix"],
  data: function () {
    return {
      tabNames: [
        { index: "Header", name: "Header", disabled: false },
        { index: "Body", name: "Body", disabled: false },
      ],
      connectionInfo: null,
      testJSConfig: this.result.additional.testJSConfig,
      // additionalStepsInfo: this.result.additional.additionalStepsInfo,
      ldapSearchResult: this.result.additional.ldapResult,
      evalAction: this.result.additional.evalAction,
      titleArray: {
        attribute: "Attribute",
        operator: "Operator",
        value: "Value",
      },
      decodeTypes: [
        ["JSON", "JSON"],
        ["text", "Text"],
        ["XML", "XML"],
      ],
    };
  },
  components: {
    httpStatusCode,
    httpResponseBody,
    onTimeOutNoResult,
    tabsStructure,
    errorActionFields,
    evalContentTable,
    configContainer,
  },
  computed: {
    onTimeout: {
      get() {
        var onTimeout = {};

        if (
          typeof this.localStoredStep != "undefined" &&
          typeof this.localStoredStep["res"] != "undefined"
        ) {
          if (typeof this.localStoredStep.res.onTimeout != "undefined") {
            onTimeout = this.localStoredStep.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        // let localStoredStep = { ...this.localStoredStep };
        if (typeof this.localStoredStep["res"] == "undefined") {
          this.$set(this.localStoredStep, "res", {});
        }
        this.$set(this.localStoredStep["res"], "onTimeout", newVal);

        //  this.$emit("stored-value-changed", localStoredStep);
      },
    },

    ExpectedStatusType: {
      get() {
        let ExpectedStatusType = "200";

        if (
          typeof this.evalValue["ExpectedStatusType"] != "undefined" &&
          ExpectedStatusType != ""
        ) {
          ExpectedStatusType = this.evalValue["ExpectedStatusType"];
        }
        return ExpectedStatusType;
      },
      set(newVal) {
        this.$set(this.evalValue, "ExpectedStatusType", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },

    HTTPCodes() {
      let HTTPCodes = [];
      let additionalStepsInfo = this.result.additional.additionalStepsInfo;
      if (
        typeof additionalStepsInfo["HTTP"] != "undefined" &&
        typeof additionalStepsInfo["HTTP"]["HTTPCodes"] != "undefined"
      ) {
        HTTPCodes = additionalStepsInfo["HTTP"]["HTTPCodes"];
      }
      return HTTPCodes;
    },
    headerEvalValue: {
      get() {
        let headerEvalValue = {};

        if (typeof this.evalValue["Header"] != "undefined") {
          headerEvalValue = this.evalValue["Header"];
        }

        return headerEvalValue;
      },
      set(newVal) {
        this.$set(this.evalValue, "Header", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    evalValue: {
      get() {
        let evalValue = {};
        if (
          typeof this.localStoredStep["res"] != "undefined" &&
          typeof this.localStoredStep["res"]["eval"] != "undefined"
        ) {
          evalValue = this.localStoredStep["res"]["eval"];
        }
        return evalValue;
      },
      set(newVal) {
        this.updateResponseKey("eval", newVal);
      },
    },

    DecodeAs: {
      get() {
        let DecodeAs = "JSON";

        if (typeof this.evalValue["DecodeAs"] != "undefined") {
          DecodeAs = this.evalValue["DecodeAs"];
        }

        return DecodeAs;
      },
      set(newVal) {
        this.$set(this.evalValue, "DecodeAs", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    bodyEvalValue: {
      get() {
        let bodyEvalValue = {};

        if (typeof this.evalValue["Body"] != "undefined") {
          bodyEvalValue = this.evalValue["Body"];
        }

        return bodyEvalValue;
      },
      set(newVal) {
        console.log("new eval body set");
        this.$set(this.evalValue, "Body", newVal);
        this.updateResponseKey("eval", this.evalValue);
      },
    },
    OnWrongStatusType() {
      let OnWrongStatusType = {};

      if (typeof this.evalValue["OnWrongStatusType"] != "undefined") {
        OnWrongStatusType = this.evalValue["OnWrongStatusType"];
      }

      return OnWrongStatusType;
    },

    OnDecodeError() {
      let OnDecodeError = {};

      if (typeof this.evalValue["OnDecodeError"] != "undefined") {
        OnDecodeError = this.evalValue["OnDecodeError"];
      }

      return OnDecodeError;
    },
  },
  methods: {
    updateBodyEvalError(field, value) {
      this.$set(this.evalValue.OnDecodeError, field, value);
      this.updateResponseKey("eval", this.evalValue);
    },

    updateResponseKey(index, newVal) {
      // console.log("update parent");
      // let localStoredStep = { ...this.localStoredStep };

      this.$set(this.localStoredStep["res"], index, newVal);
      this.$emit("stored-value-changed", this.localStoredStep);
    },
    checkOnWrongStatusType(value) {
      // console.log("checkOnWrongStatusType");
      //  if (typeof this.evalValue["OnWrongStatusType"] == "undefined") {
      this.$set(this.evalValue, "OnWrongStatusType", value);
      // }
      //  this.$set(this.evalValue.OnWrongStatusType, field, value);
      this.updateResponseKey("eval", this.evalValue);
    },
  },
};
</script>